<template>
  <div class="row discount mt-2">
    <b-col lg="12">
      <div class="card">
        <div class="card-body">
          <h2 class="text-center text-primary">Customers Active Renewal</h2>
          <br />
          <b-row class="justify-content-center mb-4">
            <div class="col-sm-6">
              <input
                type="text"
                class="form-control"
                placeholder="type anything..."
                @input="debounceSearch"
              />
              <span v-if="typing"> <em> searching... </em> </span>
            </div>
            <div class="col-md-2">
              <b-form-select
                id="currency"
                class="mb-2"
                v-model="status"
                :options="statusOptions"
                required
                @change="onStatusChange"
              ></b-form-select>
            </div>
            <div class="col-md-2">
              <b-form-select
                id="expired_sort"
                class="mb-2"
                v-model="expired_sort"
                :options="expiredSortOptions"
                required
                @change="onExpiredSortChange"
              ></b-form-select>
            </div>
          </b-row>
          <div class="row justify-content-end mb-2">
            <button class="btn btn-sm btn-dark" @click="getRenewal">
              <i class="fa fa-refresh"></i>
            </button>
            <label for="" class="col-sm-auto">Count :</label>
            <strong class="col-sm-auto">{{ formatCount(totalRows) }}</strong>
          </div>
          <b-table
            sticky-header="500px"
            responsive="sm"
            :items="items"
            :fields="fields"
            :busy="isLoading"
            :isLoading="isLoading"
            show-empty
            striped
            select-mode="single"
            hover
          >
            <template #cell(No)="row">
              {{ (currentPage - 1) * perPage + (row.index + 1) }}.
            </template>

            <template #cell(email)="row">
              {{ row.item.email ? row.item.email : '-' }}
            </template>

            <template #cell(name)="row">
              {{ row.item.name ? row.item.name : '-' }}
            </template>

            <template #cell(masked_credit_card)="row">
              {{ hideCode(row.item.masked_credit_card, 'masked') }}
            </template>

            <template #cell(status)="row">
              <span
                v-if="row.item.renewal_status == 'new'"
                class="badge badge-primary"
              >
                {{ row.item.renewal_status }}
              </span>
              <span
                v-else-if="row.item.renewal_status == 'error'"
                class="badge badge-danger"
              >
                {{ row.item.renewal_status }}
              </span>
              <span
                v-else-if="row.item.renewal_status == 'complete'"
                class="badge badge-secondary"
              >
                {{ row.item.renewal_status }}
              </span>
              <span v-else class="badge badge-warning">{{
                row.item.renewal_status
              }}</span>
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-dark my-2">
                <b-spinner
                  class="align-middle"
                  variant="dark"
                  type="grow"
                  label="Loading .."
                >
                </b-spinner>
                Loading...
              </div>
            </template>
          </b-table>
          <b-row class="justify-content-start">
            <b-col lg="3">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aria-controls="my-table"
              />
            </b-col>
          </b-row>
        </div>
      </div>
    </b-col>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapGetters, mapActions } from 'vuex';
export default {
  name: 'Renewal',
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      filter: {},
      isLoadingSearch: false,
      items_search: [],
      fields: [
        { key: 'No', label: 'No' },
        { key: 'email', label: 'Email' },
        { key: 'name', label: 'Subcription Name' },
        { key: 'orderline_id', label: 'Orderline' },
        { key: 'masked_credit_card', label: 'Mask Credit Card' },
        { key: 'status', label: 'Renewal Status' },
        {
          key: 'start_date',
          label: 'Start Date',
          formatter: (value) => {
            return moment(value).format('DD MMM YYYY HH:mm:ss');
          },
        },
        {
          key: 'expired_date',
          label: 'Expired Date',
          formatter: function(value) {
            return moment(value).format('DD MMM YYYY HH:mm:ss');
          },
        },
      ],
      message: '',
      typing: null,
      status: 'all',
      statusOptions: [
        { value: 'all', text: 'Status' },
        { value: 'new', text: 'New' },
        { value: 'cancel', text: 'Cancel' },
        { value: 'error', text: 'Error' },
        { value: 'complete', text: 'complete' },
      ],
      expired_sort: '',
      expiredSortOptions: [
        { value: '', text: 'Expired Date' },
        { value: 'ASC', text: 'ASC' },
        { value: 'DESC', text: 'DESC' },
      ],
    };
  },
  computed: {
    ...mapGetters('renewal', { items: 'renewal' }),
    ...mapState({
      isLoading: (state) => state.renewal.isLoading,
      isError: (state) => state.renewal.isError,
      totalRows: (state) => state.renewal.totalRows,
    }),
  },
  mounted() {
    this.getRenewal();
  },
  watch: {
    currentPage: function() {
      this.getRenewal();
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Customer Active Renewal | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  methods: {
    ...mapActions('renewal', ['fetch', 'search']),
    getRenewal() {
      let payload = {
        page: this.currentPage,
        limit: this.perPage,
        status: this.status,
        q: this.message,
        expired_date: this.expired_sort,
      };

      this.fetch(payload);
    },
    debounceSearch(event) {
      this.message = null;
      this.typing = 'You are typing';
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.typing = null;
        this.message = event.target.value;
        this.search({
          q: event.target.value,
          status: this.status,
          expired_date: this.expired_sort,
          page: 1,
          limit: this.perPage,
        });
      }, 600);
    },

    onFiltered(filteredItems) {
      console.log(filteredItems);
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    formatDate(tgl) {
      return moment(tgl).format('DD MMM YY');
    },
    asyncFind(query) {
      let payload = {
        q: query,
        status: this.status,
        expired_date: this.expired_sort,
        page: 1,
        limit: this.perPage,
      };
      this.search(payload);
    },
    customLabel({ name }) {
      if (name) {
        return name;
      } else {
        return 'Type Here';
      }
    },
    formatCount(value) {
      let val = (value / 1).toFixed(0).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },

    hideCode(code, type) {
      const long = code.length;
      let stars = '';
      for (let i = 0; i < long; i++) {
        stars += '*';
      }
      let subs;
      if (type == 'token') {
        subs = code.substring(1, parseInt(long) - 11);
      } else {
        subs = code.substring(1, parseInt(long) - 1);
      }
      return code.replace(subs, stars);
    },

    onStatusChange() {
      let payload = {
        status: this.status,
        page: this.currentPage,
        limit: this.perPage,
        q: this.message,
        expired_date: this.expired_sort,
      };
      this.fetch(payload);
    },

    onExpiredSortChange() {
      let payload = {
        status: this.status,
        page: this.currentPage,
        limit: this.perPage,
        q: this.message,
        expired_date: this.expired_sort,
      };
      this.fetch(payload);
    },
  },
};
</script>

<style scoped>
@import '../../assets/css/table.css';
</style>
